<template>
  <div>
    <el-container>
      <el-main>
        <h2>{{ $t("addLogin.resetEmail.h2") }}</h2>
        <div class="box" style="background: white">
          <div class="info-title">
            {{ $t("addLogin.resetEmail.my")
            }}<span class="info">{{ this.$store.getters.email }}</span>
          </div>
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            status-icon
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              class="items"
              :label="$t('addLogin.resetEmail.new')"
              prop="newEmail"
            >
              <el-input
                v-model="ruleForm.newEmail"
                :placeholder="$t('addLogin.resetEmail.new2')"
                style="width: 300px"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item
              class="items"
              :label="$t('addLogin.resetEmail.psd')"
              prop="password"
            >
              <el-input
                v-model="ruleForm.password"
                type="password"
                style="width: 300px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="items" label-width="0px" style="width: 400px">
              <el-button type="primary" @click="submitForm('ruleForm')">{{
                $t("addLogin.resetEmail.edit")
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="$router.back()">{{
          $t("addLogin.resetEmail.return")
        }}</el-button>
        <el-button @click="$router.push('/profile/reset_password')">{{
          $t("addLogin.resetEmail.reset")
        }}</el-button>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { request_reset_email } from "@/network/profile/index.js";

export default {
  name: "ResetEmailVue",
  data() {
    return {
      ruleForm: {
        newEmail: "",
        password: "",
      },
      rules: {
        password: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("addLogin.loginPsd.place2"),
          },
        ],
        newEmail: [
          {
            required: true,
            message: this.$t("addLogin.forgetPsd.message"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("addLogin.forgetPsd.message2"),
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    resetEmail(newEmail, password) {
      request_reset_email(this.$store.getters.email, newEmail, password).then(
        (res) => {
          if (res.flag === 1) {
            this.$message({
              type: "success",
              message: this.$t("addLogin.resetEmail.message.p1"),
            });
          } else if (res.flag === 2) {
            this.$message({
              type: "warning",
              message: this.$t("addLogin.resetEmail.message.p2"),
            });
          } else if (res.flag === 3) {
            this.$message({
              type: "warning",
              message: this.$t("addLogin.resetEmail.message.p3"),
            });
          } else if (res.flag === 0) {
            this.$message({
              type: "warning",
              message: this.$t("addLogin.resetEmail.message.p4"),
            });
          }
        }
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(
            this.$t("addLogin.resetEmail.form.desc") + this.ruleForm.newEmail,
            this.$t("addLogin.resetEmail.form.title"),
            {
              confirmButtonText: this.$t("btn.confirmBtn"),
              cancelButtonText: this.$t("btn.cancelBtn"),
              type: "warning",
            }
          )
            .then(() => {
              this.resetEmail(this.ruleForm.newEmail, this.ruleForm.password);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: this.$t("addLogin.resetEmail.message.p5"),
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.newEmail = "";
    },
  },
};
</script>

<style scoped>
.el-header {
  margin-top: 20px;
}

.el-form {
  margin-top: 20px;
  text-align: left;
}

.el-form-item {
  padding-top: 20px;
}

.el-row {
  padding: 10px;
  margin-bottom: 20px;
}

.el-col {
  padding-left: 10px;
}

.el-main {
  background-color: #bfd7ed;
  color: #333;
}

.items {
  text-align: left;
  margin: 5px;
}

.info,
.el-input {
  width: 100px;
  height: 50px;
  margin-left: 50px;
}

.box {
  padding: 50px;
}

.info-title {
  margin-left: 40px;
  padding-right: 100px;
  text-align: left;
}

.el-button {
  margin: 20px;
  width: 100px;
}
</style>
