import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 修改邮箱
export function request_reset_email(email, new_email, password) {
    const data = {
        email: email,
        new_email: new_email,
        password: password
    }
    return request({
        url: `/profile/reset_email`,
        method: 'post',
        data: data
    })
}

// 重置密码
export function request_reset_password(email, password, new_password) {
    const data = {
        email: email,
        password: password,
        new_password: new_password
    }
    return request({
        url: `/profile/reset_password`,
        method: 'post',
        data: data
    })
}
